import { Table } from "antd";
import React from "react";
import NairaSign from "../../../../components/NairaSign";
import Moment from "react-moment";

const CardTable = (data: any) => {
  const amazon = require("../../../../assets/images/giftcards/amazon.png");
  const amex = require("../../../../assets/images/giftcards/amex.png");
  const vanilla = require("../../../../assets/images/giftcards/vanilla.png");
  const steam = require("../../../../assets/images/giftcards/steam.png");
  const razer = require("../../../../assets/images/giftcards/razer.png");
  const itunes = require("../../../../assets/images/giftcards/itunes.png");
  const hulu = require("../../../../assets/images/giftcards/hulu.png");
  const google = require("../../../../assets/images/giftcards/google.png");

  const columns = [
    {
      title: "Assets",
      dataIndex: "asset",
      key: "asset",
      render: (asset: string) => (
        <span>
          {asset === "amazon" && (
            <span className="flex gap-[3px] items-center text-black">
              <img src={amazon} alt="" className="h-[25px] w-[25px]" />
              <span>Amazon</span>
            </span>
          )}
          {asset === "steamy" && (
            <span className="flex gap-[3px] items-center text-black">
              <img src={steam} alt="" className="h-[25px] w-[25px]" />
              <span>Steam</span>
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Sell Rate",
      dataIndex: "sellRate",
      key: "sellRate",
      render: (sellRate: number) => (
        <span>
          <NairaSign />
          {sellRate ? sellRate.toLocaleString() : sellRate}
        </span>
      ),
    },
    {
      title: "Last Updates",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: string) => <Moment fromNow>{updatedAt}</Moment>,
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data?.data}
        className="manrope"
        pagination={false}
      />
    </div>
  );
};

export default CardTable;
