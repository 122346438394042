import { useQuery } from "react-query";
import axios from "axios";

export const useGetAccounts = () => {
  return useQuery({
    queryKey: ["useGetAccounts"],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/accounts/`
      );
      return response.data;
    },
    onError: (error) => console.error("Error fetching user data:", error),
  });
};
