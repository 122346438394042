import { Table } from "antd";
import React from "react";
import NairaSign from "../../../../components/NairaSign";
import Moment from "react-moment";

const CryptoTable = (data: any) => {
  const btc = require("../../../../assets/images/btcc.png");
  const sol = require("../../../../assets/images/sol.png");
  const usdt = require("../../../../assets/images/usdt.png");
  const wave = require("../../../../assets/images/wave.png");
  const eth = require("../../../../assets/images/eth.png");
  const bnb = require("../../../../assets/images/bnb.png");

  const columns = [
    {
      title: "Assets",
      dataIndex: "asset",
      key: "asset",
      render: (asset: string) => (
        <span>
          {asset === "ethereum" && (
            <span className="flex gap-[3px] items-center text-black">
              <img src={eth} alt="" className="h-[25px] w-[25px]" />
              <span>ETH</span>
            </span>
          )}
          {asset === "usd_tether" && (
            <span className="flex gap-[3px] items-center text-black">
              <img src={usdt} alt="" className="h-[25px] w-[25px]" />
              <span>USDT</span>
            </span>
          )}
          {asset === "binance" && (
            <span className="flex gap-[3px] items-center text-black">
              <img src={bnb} alt="" className="h-[25px] w-[25px]" />
              <span>BNB</span>
            </span>
          )}
          {asset === "btc" && (
            <span className="flex gap-[3px] items-center text-black">
              <img src={btc} alt="" className="h-[25px] w-[25px]" />
              <span>BTC</span>
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Buy Rate",
      dataIndex: "buyRate",
      key: "buyRate",
      render: (buyRate: number) => (
        <span>
          <NairaSign />
          {buyRate ? buyRate.toLocaleString() : buyRate}
        </span>
      ),
    },
    {
      title: "Sell Rate",
      dataIndex: "sellRate",
      key: "sellRate",
      render: (sellRate: number) => (
        <span>
          <NairaSign />
          {sellRate ? sellRate.toLocaleString() : sellRate}
        </span>
      ),
    },
    {
      title: "Last Updates",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt: string) => (
        <Moment interval={0} format="DD-MM-YYYY hh:mm a">
          {updatedAt}
        </Moment>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={data?.data}
        className="manrope"
        pagination={false}
      />
    </div>
  );
};

export default CryptoTable;
