import React, { useState } from "react";
import { useGetAdmins } from "../../../hooks/Admin/Admin/useGetAdmins";
import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
import AdminTable from "./AdminTable";
import { Clipboard, X, UserRoundCog } from "lucide-react";
import { Modal, Input, Select, Spin, message, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useAddAdmin } from "../../../hooks/Admin/Admin/useAddAdmin";

const Admins = () => {
  const { data, refetch, isLoading: isLoadingAdmins } = useGetAdmins();
  const [payload, setPayload] = useState({
    email: "",
    firstName: "",
    lastName: "",
    role: "",
  });
  const [showModal, setShowModal] = useState(false);
  const { mutateAsync, isLoading } = useAddAdmin();

  const handleChange = (field: any, value: any) => {
    setPayload((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddAdmin = async (e: any) => {
    e.preventDefault();
    if (
      !payload.email ||
      !payload.firstName ||
      !payload.lastName ||
      !payload.role
    ) {
      message.error("All fields are required!");
      return;
    }

    try {
      await mutateAsync(payload);
      //   message.success("Admin added successfully!");
      setShowModal(false);
      setPayload({ email: "", firstName: "", lastName: "", role: "" });
      refetch();
    } catch (error: any) {
      message.error(error.message || "Something went wrong!");
    }
  };

  return (
    <AdminDashboardLayout>
      <div className="flex gap-2 px-3 my-[15px]">
        <div className="bg-[#F1F2FF] rounded-md gap-[7px] p-2 flex flex-1 items-center text-[#3A3FB8]">
          <Clipboard className="w-6 h-6" />
          <p className="text-[15px]">
            Tooltip: Invite a new team member by setting up their role and
            permissions.
          </p>
        </div>
        <button
          onClick={() => setShowModal(true)}
          className="p-[15px] rounded-md bg-[#3A3FB8] text-white text-[15px]"
        >
          Add New Admin
        </button>
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          closeIcon={false}
          title={
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="p-2 bg-[#eee] rounded-full">
                  <UserRoundCog className="w-6 h-6 text-[#3A3FB8]" />
                </span>
                <h2 className="text-lg font-medium">Add New Admin</h2>
              </div>
              <button
                onClick={() => setShowModal(false)}
                className="flex items-center gap-[5px] p-2 rounded-md bg-[#f1f1f1] border-[1px] border-[#D8D8D8] text-[#474747]"
              >
                <X className="w-6 h-6" />
                close
              </button>
            </div>
          }
        >
          <form className="space-y-4 mt-[20px]" onSubmit={handleAddAdmin}>
            <div>
              <label className="block text-gray-700 text-sm mb-[8px]">
                Email Address
              </label>
              <Input
                placeholder="admin@gmail.com"
                size="large"
                style={{ height: "44px" }}
                className="w-full rounded-md px-[18px] py-[18px]"
                value={payload.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm mb-[8px]">
                  First Name
                </label>
                <Input
                  placeholder="First Name"
                  className="w-full rounded-md px-[18px] py-[18px]"
                  style={{ height: "44px" }}
                  value={payload.firstName}
                  onChange={(e) => handleChange("firstName", e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm mb-[8px]">
                  Last Name
                </label>
                <Input
                  placeholder="Last Name"
                  className="w-full rounded-md px-[18px] py-[18px]"
                  size="large"
                  style={{ height: "44px" }}
                  value={payload.lastName}
                  onChange={(e) => handleChange("lastName", e.target.value)}
                />
              </div>
            </div>
            <div>
              <label className="block text-gray-700 text-sm mb-[8px]">
                Role
              </label>
              <Select
                className="w-full rounded-md"
                placeholder="Select Role"
                size="large"
                style={{ height: "44px" }}
                value={payload.role}
                onChange={(value) => handleChange("role", value)}
              >
                <Select.Option value="admin">Admin</Select.Option>
              </Select>
            </div>
            <div className="bg-[#F1F2FF] text-[#3A3FB8] flex items-center p-3 rounded-md">
              <Clipboard className="w-5 h-5 mr-2" />
              <p className="text-sm">
                A randomly generated password will be sent to admin’s mail.
              </p>
            </div>
            <Button
              type="primary"
              block
              size="large"
              loading={isLoading}
              className="bg-[#3A3FB8] hover:bg-[#2F37A5] text-white rounded-md h-[50px]"
              htmlType="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Add Admin"
              )}
            </Button>{" "}
          </form>
        </Modal>
      </div>

      <AdminTable data={data?.data?.data} isLoading={isLoadingAdmins} />
    </AdminDashboardLayout>
  );
};

export default Admins;
