import { useQuery } from "react-query";
import { useGetAdminToken } from "../../../utils/useGetAdminToken";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";

export const useGetAdminCardRates = () => {
  const token = useGetAdminToken();

  return useQuery({
    queryKey: "getAdminCardRates",
    queryFn: async () => {
      if (!token) {
        throw new Error("Token not found");
      }

      const response = await adminAxiosInstance.get("/rates/cards", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
    onSuccess: (data) => {},
    onError: (error) => {
      console.error("Error fetching user data:", error);
    },
  });
};
