import { useQuery } from "react-query";

export const useGetAdminCryptoRates = () => {
  return useQuery({
    queryKey: "getAdminCryptoRates",
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/rates/cryptos`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch crypto rates");
      }

      return response.json();
    },
    onError: (error) => {
      console.error("Error fetching crypto rates:", error);
    },
  });
};
