import { cn } from "../../utils/index";
import { ScaffoldType } from "./types";

const Scaffold = ({
  left,
  right,
  children,
  className,
  containerClassName,
}: ScaffoldType) => {
  return (
    <div
      className={cn(
        "border-[1px] border-[#] border-[#eee] rounded-md mb-[15px]",
        containerClassName
      )}
    >
      <div className="p-[20px] font-medium border-b-[1px] border-[#eeeeee] flex justify-between items-center">
        <span>{left}</span>
        <span>{right}</span>
      </div>
      <div className={cn("p-[10px]", className)}>{children}</div>
    </div>
  );
};

export default Scaffold;
