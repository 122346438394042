import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="grid grid-cols-2 gap-4 p-6 bg-gray-100 animate-pulse">
      {/* Left Section */}
      <div className="space-y-4">
        <div className="h-6 w-32 bg-gray-300 rounded"></div>

        <div className="space-y-2">
          <div className="h-4 w-24 bg-gray-300 rounded"></div>
          <div className="h-4 w-16 bg-gray-300 rounded"></div>
          <div className="h-4 w-28 bg-gray-300 rounded"></div>
          <div className="h-4 w-40 bg-gray-300 rounded"></div>
        </div>

        <div className="h-6 w-24 bg-red-300 rounded"></div>

        <div className="h-6 w-full bg-gray-300 rounded"></div>

        <div className="space-y-2">
          <div className="h-4 w-40 bg-gray-300 rounded"></div>
          <div className="h-4 w-32 bg-gray-300 rounded"></div>
          <div className="h-4 w-48 bg-gray-300 rounded"></div>
          <div className="h-4 w-20 bg-gray-300 rounded"></div>
        </div>

        <div className="space-y-2">
          <div className="h-4 w-24 bg-gray-300 rounded"></div>
          <div className="h-4 w-32 bg-gray-300 rounded"></div>
          <div className="h-4 w-48 bg-gray-300 rounded"></div>
        </div>
      </div>

      {/* Right Section */}
      <div className="space-y-4">
        <div className="flex gap-2">
          <div className="h-10 w-32 bg-gray-300 rounded"></div>
          <div className="h-10 w-32 bg-gray-300 rounded"></div>
          <div className="h-10 w-32 bg-gray-300 rounded"></div>
        </div>

        <div className="h-6 w-24 bg-gray-300 rounded"></div>
        <div className="h-4 w-40 bg-gray-300 rounded"></div>

        <div className="space-y-2">
          <div className="h-4 w-40 bg-gray-300 rounded"></div>
          <div className="h-4 w-32 bg-gray-300 rounded"></div>
          <div className="h-4 w-48 bg-gray-300 rounded"></div>
        </div>

        <div className="space-y-2">
          <div className="h-4 w-24 bg-gray-300 rounded"></div>
          <div className="h-4 w-32 bg-gray-300 rounded"></div>
          <div className="h-4 w-48 bg-gray-300 rounded"></div>
        </div>

        <div className="h-6 w-full bg-gray-300 rounded"></div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
