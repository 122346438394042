import React from "react";
import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
// import { useGetCustomers } from "../../../hooks/Admin/Account/useGetCustomers";

type Props = {};

const Customer = (props: Props) => {
  //   const { data } = useGetCustomers({});
  return (
    <AdminDashboardLayout>
      Customer
      <br />
      {/* {JSON.stringify(data)} */}
    </AdminDashboardLayout>
  );
};

export default Customer;
