import { useMutation } from "react-query";
import { message } from "antd";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";
import { useGetAdminToken } from "../../../utils/useGetAdminToken";

interface AdminPayload {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

interface ApiResponse {
  message: string;
  data?: any;
}

export const useAddAdmin = () => {
  const token = useGetAdminToken();

  return useMutation<ApiResponse, Error, AdminPayload>(
    (payload) =>
      adminAxiosInstance
        .post("/admins/add-admin", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data.message || "Admin added successfully");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message || "Something went wrong");
      },
    }
  );
};
