import AdminDashboardLayout from "../../../common/AdminDashboardLayout";

const AdminReport = () => {
  return (
    <AdminDashboardLayout>
      111 Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, porro
      at magnam nemo quam quisquam nam architecto vero incidunt sit sunt placeat
      tempore veniam fuga voluptatum repellendus nostrum facere necessitatibus!
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, porro at
      magnam nemo quam quisquam nam architecto vero incidunt sit sunt placeat
      tempore veniam fuga voluptatum repellendus nostrum facere necessitatibus!
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, porro at
      magnam nemo quam quisquam nam architecto vero incidunt sit sunt placeat
      tempore veniam fuga voluptatum repellendus nostrum facere necessitatibus!
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, porro at
      magnam nemo quam quisquam nam architecto vero incidunt sit sunt placeat
      tempore veniam fuga voluptatum repellendus nostrum facere necessitatibus!
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi, porro at
      magnam nemo quam quisquam nam architecto vero incidunt sit sunt placeat
      tempore veniam fuga voluptatum repellendus nostrum facere necessitatibus!
    </AdminDashboardLayout>
  );
};

export default AdminReport;
