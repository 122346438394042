/* eslint-disable react/display-name */
import { message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const withAdminAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    const stringifyToken = localStorage.getItem("FLYNTPAY_ADMIN_TOKEN");
    // const parsedToken = stringifyToken ? JSON.parse(stringifyToken) : null;

    useEffect(() => {
      if (!stringifyToken) {
        navigate("/admin/login");
        message.info("Proceed to Login");
        // toast('Unauthorized, proceed to sign in');
        // return null;
      }
    }, [navigate, stringifyToken]);

    return <WrappedComponent {...props} />;
  };
};

export default withAdminAuth;
