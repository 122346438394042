import React, { ChangeEvent, useState } from "react";
import { useAdminLogin } from "../../../hooks/Admin/Auth/useAdminLogin";
import { Input, Button, Card, Typography, Form, message } from "antd";

const { Title } = Typography;

const AdminLogin = () => {
  const [payload, setPayload] = useState({
    email: "",
    password: "",
  });

  const { mutateAsync, isLoading } = useAdminLogin();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await mutateAsync(payload);
      message.success("Login successful");
    } catch (error: any) {
      message.error(error?.response?.data?.message || "Login failed");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <Card className="w-[350px] shadow-md rounded-md">
        <Title level={3} className="text-center text-[#1C1F60]">
          Admin Login
        </Title>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: "email", message: "Enter a valid email" }]}
          >
            <Input
              name="email"
              value={payload.email}
              disabled={isLoading}
              onChange={handleChange}
              placeholder="Enter your email"
              size="large"
              style={{ height: "44px" }}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ message: "Please enter your password" }]}
          >
            <Input.Password
              name="password"
              value={payload.password}
              disabled={isLoading}
              onChange={handleChange}
              placeholder="Enter your password"
              size="large"
              style={{ height: "44px" }}
            />
          </Form.Item>
          <Button
            type="primary"
            block
            htmlType="submit"
            loading={isLoading}
            disabled={!payload.email || !payload.password}
            className="bg-gradient-to-b from-[#686DEA] to-[#1C1F60] hover:opacity-90"
            size="large"
            style={{ height: "44px" }}
          >
            Login
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default AdminLogin;
