import { useMutation } from "react-query";
import { message } from "antd";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";

export const useAdminCreateRates = () => {
  interface PayloadType {
    asset: string;
    buyRate: string;
    sellRate: string;
    assetType: string;
    walletAddress: string;
    walletImageUrl: string;
  }

  return useMutation(
    (payload: PayloadType) => {
      const token = localStorage.getItem("FLYNTPAY_ADMIN_TOKEN");
      return adminAxiosInstance
        .post(
          "/rates",
          {
            ...payload,
            buyRate: +payload?.buyRate * 100,
            sellRate: payload?.sellRate === "" ? 0 : +payload?.sellRate * 100,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res: any) => res.data);
    },
    {
      onSuccess: (data: any) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message || "Error occurred");
      },
    }
  );
};
