import React, { useRef, useState } from "react";
import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
import { Button, Card, Input, message, Spin, Table } from "antd";
import { useAddAccount } from "../../../hooks/Admin/Account/useAddAccount";
import axios from "axios";
import { CloudUpload, X } from "lucide-react";
// import { useAdminCreateRates } from "../../../hooks/Admin/Rates/useAdminCreateRates";
import { LoadingOutlined } from "@ant-design/icons";
import { useGetAccounts } from "../../../hooks/Admin/Account/useGetAccounts";

const initialState = {
  accountName: "",
  accountNumber: "",
  bankName: "",
  imageUrl: "",
};

const AdminPayout = () => {
  const [accountPayload, setAccountPayload] = useState(initialState);

  const { mutateAsync, isLoading: isLoadingAccount } = useAddAccount();
  const { data, isLoading } = useGetAccounts();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleAccount = () => {
    mutateAsync(accountPayload, {
      onSuccess: () => {
        setAccountPayload(initialState);
      },
    });
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("File uploaded successfully!");
      setAccountPayload({ ...accountPayload, imageUrl: response.data.data });
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleAddDetails = (field: string, value: string) => {
    setAccountPayload((prev) => ({ ...prev, [field]: value }));
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      handleUpload(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const columns = [
    {
      title: "Account Name",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Account Number",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Image",
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (text: string) => (
        <img src={text} alt="account" style={{ width: 50, height: 50 }} />
      ),
    },
  ];

  // const columns = [
  //   {
  //     title: "Assets",
  //     dataIndex: "asset",
  //     key: "asset",
  //     render: (asset: string) => <span>ksksk</span>,
  //   },
  // {
  //   title: "Buy Rate",
  //   dataIndex: "buyRate",
  //   key: "buyRate",
  //   render: (buyRate: number) => (
  //     <span>
  //       <NairaSign />
  //       {buyRate ? buyRate.toLocaleString() : buyRate}
  //     </span>
  //   ),
  // },
  // {
  //   title: "Sell Rate",
  //   dataIndex: "sellRate",
  //   key: "sellRate",
  //   render: (sellRate: number) => (
  //     <span>
  //       <NairaSign />
  //       {sellRate ? sellRate.toLocaleString() : sellRate}
  //     </span>
  //   ),
  // },
  // {
  //   title: "Last Updates",
  //   dataIndex: "updatedAt",
  //   key: "updatedAt",
  //   render: (updatedAt: string) => (
  //     <Moment interval={0} format="DD-MM-YYYY hh:mm a">
  //       {updatedAt}
  //     </Moment>
  //   ),
  // },
  // ];

  return (
    <AdminDashboardLayout title="Payout Account">
      <div className="p-4">
        {/* <p>{JSON.stringify(data)}</p> */}
        {/* <Table
          columns={columns}
          dataSource={data}
          rowKey="key"
          loading={isLoading}
        /> */}
        {/* <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure id
          deserunt officia. Nostrum atque, sint itaque, aperiam minus provident
          accusantium cum facilis hic ea, sed repellendus expedita obcaecati
          quas voluptate!
        </p> */}
        <div className="flex justify-center mx-auto">
          <Card className="max-w-[450px]" title="Add Payout Account">
            <div className="space-y-4 mt-5">
              <div>
                <label className="block text-gray-700 text-sm mb-2">
                  Account Name
                </label>
                <Input
                  placeholder=""
                  size="large"
                  style={{ height: "44px" }}
                  className="w-full rounded-md px-4 py-2"
                  value={accountPayload?.accountName}
                  onChange={(e) =>
                    handleAddDetails("accountName", e.target.value)
                  }
                />
              </div>
              <div className="flex gap-3">
                <div className="w-1/2">
                  <label className="block text-gray-700 text-sm mb-2">
                    Account Number
                  </label>
                  <Input
                    placeholder=""
                    className="w-full rounded-md px-4 py-2"
                    style={{ height: "44px" }}
                    value={accountPayload?.accountNumber}
                    onChange={(e) =>
                      handleAddDetails("accountNumber", e.target.value)
                    }
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-700 text-sm mb-2">
                    Bank Name
                  </label>
                  <Input
                    placeholder=""
                    className="w-full rounded-md px-4 py-2"
                    style={{ height: "44px" }}
                    value={accountPayload?.bankName}
                    onChange={(e) =>
                      handleAddDetails("bankName", e.target.value)
                    }
                  />
                </div>
              </div>
              <div>
                {accountPayload?.imageUrl === "" ? (
                  <div
                    className="cursor-pointer border border-dashed border-[#474747] bg-transparent mt-[10px] rounded-[12px] p-[20px] flex flex-col items-center text-center"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    onClick={handleClick}
                  >
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          handleUpload(e.target.files[0]);
                        }
                      }}
                    />
                    <div className="flex flex-col items-center gap-2">
                      <div className="bg-[#242424] text-[#727272] p-3 rounded-full">
                        <CloudUpload size={30} />
                      </div>
                      <p className="text-white font-medium">
                        Drag and drop receipt to upload
                      </p>
                      <p className="text-gray-400 text-sm">
                        Accepted formats:{" "}
                        <span className="text-gray-300">.JPEG, .PNG</span>{" "}
                        Maximum file size: 20MB
                      </p>
                      <p className="text-[#3A3FB8] text-sm cursor-pointer underline">
                        Or browse file
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="relative w-40 h-40 border border-gray-600 rounded-lg overflow-hidden cursor-pointer">
                    <img
                      src={accountPayload?.imageUrl}
                      alt="Uploaded receipt"
                      className="w-full h-full object-cover"
                      // onClick={() => setIsModalOpen(true)}
                    />
                    <button
                      className="absolute top-1 right-1 bg-black/60 text-white p-1 rounded-full"
                      onClick={(e) => {
                        e.stopPropagation();
                        setAccountPayload({ ...accountPayload, imageUrl: "" });
                      }}
                    >
                      <X size={18} />
                    </button>
                  </div>
                )}
              </div>
              <Button
                onClick={handleAccount}
                type="primary"
                block
                size="large"
                loading={isLoadingAccount}
                className="bg-[#3A3FB8] hover:bg-[#2F37A5] text-white rounded-md h-[50px]"
                htmlType="submit"
                // disabled={trie}
              >
                {isLoadingAccount ? (
                  <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                  "Add Account"
                )}
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminPayout;
