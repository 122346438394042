import React, { useRef } from "react";
import UserAppLayout from "../../../common/UserAppLayout";
import { useBuyCryptohelper } from "./useBuyCryptohelper";
import { useCreateOrderMutation } from "../../../hooks/Orders/useCreateOrderMutation";
import { useGetAdminCryptoRates } from "../../../hooks/Admin/Rates/useGetAdminCryptoRates";
import CryptoSelection from "./Steps/CryptoSelection";
import AmountInput from "./Steps/AmountInput";
import ReviewOrder from "./Steps/ReviewOrder";
import UploadReceipt from "./Steps/UploadReceipt";
import { useGetAccounts } from "../../../hooks/Admin/Account/useGetAccounts";

const BuyCrypto: React.FC = () => {
  const {
    step,
    setStep,
    payload,
    setPayload,
    tokens,
    handleChange,
    getBuyRate,
    exchange,
    btc,
    bnb,
    eth,
    sol,
    usdt,
    wave,
    handleUpload,
    setIsModalOpen,
    initialState,
  } = useBuyCryptohelper();
  const { mutateAsync, isLoading } = useCreateOrderMutation({
    payload,
    setPayload,
    initialState,
  });
  const { data: rates } = useGetAdminCryptoRates();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data: dhhh } = useGetAccounts();

  const handleSubmit = () => {
    mutateAsync();
  };

  return (
    <UserAppLayout>
      <p className="text-white">dhhh{JSON.stringify(dhhh?.data)}</p>
      <p className="text-center md:text-[50px] text-[30px] md:mt-[50px] mt-[25px] text-white font-bold">
        Buy Crypto
      </p>
      <h3 className="text-center text-[#727272] md:mt-[21px] md:mb-[50px] mb-[30px]">
        Buy digital asset via bank transfer
      </h3>
      <div className="mx-auto md:w-[540px] w-full bg-[#0D0D0D] rounded-md shadow-md mb-[40px]">
        <div className="p-[20px] flex gap-2 border-b-[1px] border-[#242424] rounded-t-[20px]">
          <h3 className="text-[#c2c2c2] text-[13px]">Step {step}/4</h3>
        </div>
        {step === 1 && (
          <CryptoSelection
            tokens={tokens}
            payload={payload}
            setPayload={setPayload}
            handleChange={handleChange}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <AmountInput
            payload={payload}
            setPayload={setPayload}
            getBuyRate={getBuyRate}
            exchange={exchange}
            setStep={setStep}
            rates={rates}
          />
        )}
        {step === 3 && (
          <ReviewOrder
            payload={payload}
            usdt={usdt}
            btc={btc}
            wave={wave}
            eth={eth}
            sol={sol}
            getBuyRate={getBuyRate}
            setStep={setStep}
          />
        )}
        {step === 4 && (
          <UploadReceipt
            setPayload={setPayload}
            fileInputRef={fileInputRef}
            payload={payload}
            exchange={exchange}
            setIsModalOpen={setIsModalOpen}
            handleUpload={handleUpload}
            // setPayload={setPayload}
            handleSubmit={handleSubmit}
            setStep={setStep}
            isLoading={isLoading}
          />
        )}
      </div>
    </UserAppLayout>
  );
};

export default BuyCrypto;
