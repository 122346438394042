import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";

export const useAdminLogin = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: any) =>
      adminAxiosInstance.post("/admins/login", payload).then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data?.message);
        navigate("/admin/dashboard");
        localStorage.setItem("FLYNTPAY_ADMIN_TOKEN", data?.data);
      },
      onError: (error: any) => {
        console.error(error);
      },
    }
  );
};
