import { useRef, useState } from "react";
import {
  Button,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Tabs,
  TabsProps,
} from "antd";
import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
import { useGetAdminCardRates } from "../../../hooks/Admin/Rates/useGetAdminCardRates";
import { useGetAdminCryptoRates } from "../../../hooks/Admin/Rates/useGetAdminCryptoRates";
import CryptoTable from "./Tables/CryptoTable";
import CardTable from "./Tables/CardTable";
import { CloudUpload, X } from "lucide-react";
import { useAdminCreateRates } from "../../../hooks/Admin/Rates/useAdminCreateRates";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";

const initialState = {
  asset: "",
  buyRate: "",
  sellRate: "",
  assetType: "",
  walletAddress: "",
  walletImageUrl: "",
};

const AdminRate = () => {
  const { data: cards } = useGetAdminCardRates();
  const { data: crypto } = useGetAdminCryptoRates();
  const [showModal, setShowModal] = useState(false);

  const [payload, setPayload] = useState(initialState);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Crypto",
      children: <CryptoTable data={crypto?.data?.data} />,
    },
    {
      key: "2",
      label: "Giftcards",
      children: <CardTable data={cards?.data?.data} />,
    },
  ];

  const handleChange = (field: string, value: string) => {
    setPayload((prev) => ({ ...prev, [field]: value }));
  };

  const { mutateAsync, isLoading } = useAdminCreateRates();

  const handleCreateRates = async () => {
    mutateAsync(payload);
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload({ ...payload, walletImageUrl: response.data.data });
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      handleUpload(e.dataTransfer.files[0]);
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <AdminDashboardLayout title="Rates">
      <div className="px-8">
        <div className="flex justify-end gap-2 items-center mt-[15px]">
          <Button
            onClick={() => {
              setShowModal(true);
            }}
            className="p-4 rounded-md bg-[#3A3FB8] text-white text-base"
          >
            Add New Asset
          </Button>
        </div>
        <Modal
          open={showModal}
          onCancel={() => setShowModal(false)}
          footer={null}
          closeIcon={false}
          title={
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-medium">Add New Asset</h2>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setPayload(initialState);
                }}
                className="flex items-center gap-2 p-2 rounded-md bg-[#f1f1f1] border border-[#D8D8D8] text-[#474747]"
              >
                <X className="w-6 h-6" />
                Close
              </Button>
            </div>
          }
        >
          <div className="space-y-4 mt-5">
            <div>
              <label className="block text-gray-700 text-sm mb-[8px]">
                Asset Type
              </label>
              <Select
                className="w-full rounded-md"
                placeholder="Select Role"
                size="large"
                style={{ height: "44px" }}
                value={payload.assetType}
                onChange={(value) => {
                  handleChange("assetType", value);
                  handleChange("sellRate", "");
                }}
              >
                <Select.Option value="crypto">Crypto</Select.Option>
                <Select.Option value="gift_card">Gift Card</Select.Option>
              </Select>
            </div>
            <div>
              <label className="block text-gray-700 text-sm mb-2">
                Asset Name
              </label>
              <Input
                placeholder=""
                size="large"
                style={{ height: "44px" }}
                className="w-full rounded-md px-4 py-2"
                value={payload.asset}
                onChange={(e) => handleChange("asset", e.target.value)}
              />
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm mb-2">
                  Buy Rate
                </label>
                <Input
                  placeholder="Buy Rate"
                  className="w-full rounded-md px-4 py-2"
                  style={{ height: "44px" }}
                  value={payload.buyRate}
                  onChange={(e) => handleChange("buyRate", e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-gray-700 text-sm mb-2">
                  Sell Rate
                </label>
                <Input
                  placeholder="Sell Rate"
                  className="w-full rounded-md px-4 py-2"
                  style={{ height: "44px" }}
                  disabled={payload.assetType === "gift_card"}
                  value={payload.sellRate}
                  onChange={(e) => handleChange("sellRate", e.target.value)}
                />
              </div>
            </div>
            <div className="">
              <label className="block text-gray-700 text-sm mb-2">
                Wallet Address
              </label>
              <Input
                placeholder=""
                className="w-full rounded-md px-4 py-2"
                style={{ height: "44px" }}
                value={payload.walletAddress}
                onChange={(e) => handleChange("walletAddress", e.target.value)}
              />
            </div>
            {payload?.walletImageUrl === "" ? (
              <div
                className="cursor-pointer border border-dashed border-[#474747] bg-transparent mt-[10px] rounded-[12px] p-[20px] flex flex-col items-center text-center"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                onClick={handleClick}
              >
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  className="hidden"
                  ref={fileInputRef}
                  onChange={(e) => {
                    if (e.target.files?.length) {
                      handleUpload(e.target.files[0]);
                    }
                  }}
                />
                <div className="flex flex-col items-center gap-2">
                  <div className="bg-[#242424] text-[#727272] p-3 rounded-full">
                    <CloudUpload size={30} />
                  </div>
                  <p className="text-white font-medium">
                    Drag and drop receipt to upload
                  </p>
                  <p className="text-gray-400 text-sm">
                    Accepted formats:{" "}
                    <span className="text-gray-300">.JPEG, .PNG</span> Maximum
                    file size: 20MB
                  </p>
                  <p className="text-[#3A3FB8] text-sm cursor-pointer underline">
                    Or browse file
                  </p>
                </div>
              </div>
            ) : (
              <div className="relative w-40 h-40 border border-gray-600 rounded-lg overflow-hidden cursor-pointer">
                <img
                  src={payload?.walletImageUrl}
                  alt="Uploaded receipt"
                  className="w-full h-full object-cover"
                  // onClick={() => setIsModalOpen(true)}
                />
                <button
                  className="absolute top-1 right-1 bg-black/60 text-white p-1 rounded-full"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPayload({ ...payload, walletImageUrl: "" });
                  }}
                >
                  <X size={18} />
                </button>
              </div>
            )}

            <Button
              onClick={(e) => {
                e.preventDefault();
                handleCreateRates();
              }}
              type="primary"
              block
              size="large"
              loading={isLoading}
              className="bg-[#3A3FB8] hover:bg-[#2F37A5] text-white rounded-md h-[50px]"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spin indicator={<LoadingOutlined spin />} />
              ) : (
                "Add Asset"
              )}
            </Button>
          </div>
        </Modal>

        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminRate;
