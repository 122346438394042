import React from "react";

const AmountInput = ({
  payload,
  setPayload,
  getBuyRate,
  exchange,
  setStep,
}: any) => {
  return (
    <div className="px-[20px]">
      <h3 className="mt-[10px] text-[#727272]">Amount</h3>
      <input
        type="number"
        className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
        placeholder="Enter amount"
        value={payload?.amount}
        onChange={(e) => {
          const amount = +e.target.value || 0;
          const networkFee = amount * 0.01;
          const totalAmount = amount + networkFee;
          const receivedCrypto = amount / getBuyRate(payload.cryptoType);

          setPayload({
            ...payload,
            amount: e.target.value,
            networkFee: networkFee.toString(),
            totalAmount: totalAmount.toString(),
            receivedCrypto: receivedCrypto.toString(),
          });
        }}
      />
      <div>
        <p className="text-white py-[20px]">
          You will recieve: - &#8358;{" "}
          {getBuyRate(payload.cryptoType) && payload.amount
            ? (
                +payload.amount * getBuyRate(payload.cryptoType)
              ).toLocaleString()
            : 0}
        </p>
      </div>
      <div className="flex p-[15px] rounded-md gap-[6px] items-center bg-[#5d5fa62a] border-dashed border-[2px] border-[#5D60A6]">
        <img src={exchange} alt="" className="h-[34px] w-[34px]" />
        <span className="text-[#5D60A6]">
          Buying rate for is &#8358;{" "}
          {getBuyRate(payload.cryptoType).toLocaleString()}$
        </span>
      </div>
      <div className="flex gap-[10px]">
        <button
          onClick={() => setStep(1)}
          className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
        >
          Back
        </button>
        <button
          disabled={!payload.amount}
          onClick={() => setStep(3)}
          className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AmountInput;
