import { useMutation } from "react-query";
import { message } from "antd";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";
import { useGetAdminToken } from "../../../utils/useGetAdminToken";

export const useAddAccount = () => {
  const token = useGetAdminToken();

  return useMutation(
    (payload: any) =>
      adminAxiosInstance
        .post("/accounts", payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data?.message);
      },
      onError: (error: any) => {
        console.error(error);
      },
    }
  );
};
