import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
import { useGetSalesSummaryQuery } from "../../../hooks/Admin/Orders/useGetSalesSummaryQuery";

const AdminDashbard = () => {
  const { data: summary } = useGetSalesSummaryQuery();

  return (
    <AdminDashboardLayout>
      <div className="px-4 mt-4">
        <h3 className="text-[20px] font-bold text-[#]">Orders</h3>
        <div className="grid grid-cols-3 gap-3 mb-4 mt-4">
          <div>
            <h3 className="font-bold text-black mb-1">All Orders</h3>
            <p className="text-[#3d3d3d]">{summary?.data?.allOrders}</p>
          </div>
          <div>
            <h3 className="font-bold text-black mb-1">Pending</h3>
            <p className="text-[#3d3d3d]">{summary?.data?.pending}</p>
          </div>
          <div>
            <h3 className="font-bold text-black mb-1">Processing</h3>
            <p className="text-[#3d3d3d]">{summary?.data?.processing}</p>
          </div>
          <div>
            <h3 className="font-bold text-black mb-1">Completed</h3>
            <p className="text-[#3d3d3d]">{summary?.data?.completed}</p>
          </div>
          <div>
            <h3 className="font-bold text-black mb-1">Cancelled</h3>
            <p className="text-[#3d3d3d]">{summary?.data?.cancelled}</p>
          </div>
          <div>
            <h3 className="font-bold text-black mb-1">New Orders</h3>
            <p className="text-[#3d3d3d]">{summary?.data?.newOrders}</p>
          </div>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminDashbard;
