import { Table, Tag } from "antd";

type User = {
  uniqueId: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string;
  isSuspended: boolean;
  lastLogin: string;
};

const columns = [
  { title: "ID", dataIndex: "uniqueId", key: "uniqueId" },
  {
    title: "Name",
    key: "name",
    render: (_: any, record: User) => `${record.firstName} ${record.lastName}`,
  },
  { title: "Email Address", dataIndex: "email", key: "email" },
  {
    title: "Role",
    dataIndex: "roles",
    key: "roles",
    render: (roles: string) => (
      <Tag color="blue" className="capitalize">
        {roles}
      </Tag>
    ),
  },
  {
    title: "Status",
    dataIndex: "isSuspended",
    key: "isSuspended",
    render: (isSuspended: boolean) => (
      // <Tag color={isSuspended ? "red" : "green"}>
      //   {isSuspended ? "Suspended" : "Active"}
      // </Tag>
      <span className="capitalize flex items-center gap-[5px] p-[10px] rounded-md border-[1px] text-[#474747] border-[#eeeeee]">
        <span
          className={`h-[10px] w-[10px] rounded-full ${
            isSuspended ? "bg-red-500" : "bg-green-500"
          }`}
        ></span>
        <span className="text-[12px]">
          {isSuspended ? "Suspended" : "Active"}
        </span>
      </span>
    ),
  },
  { title: "Last Login", dataIndex: "lastLogin", key: "lastLogin" },
];

const AdminTable = ({
  data,
  isLoading,
}: {
  data: User[];
  isLoading: boolean;
}) => {
  return (
    <div className="p-4">
      <Table
        columns={columns}
        dataSource={data}
        rowKey="uniqueId"
        loading={isLoading}
      />
    </div>
  );
};

export default AdminTable;
