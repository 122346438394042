import { Link, useParams } from "react-router-dom";
import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
import { useGetOrderQuery } from "../../../hooks/Admin/Orders/useGetOrderQuery";
import Scaffold from "../../../components/Scaffold";
import { Modal, Skeleton } from "antd";
import { ArrowLeftRight, Copy } from "lucide-react";
import { useState } from "react";
import { useMarkAsProcessingMutation } from "../../../hooks/Admin/Orders/useMarkAsProcessingMutation";
import { useMarkAsCompleteMutation } from "../../../hooks/Admin/Orders/useMarkAsCompleteMutation";
import { useMarkAsCancelMutation } from "../../../hooks/Admin/Orders/useMarkAsCancelMutation";
import StatusTag from "../../../components/StatusTag";
import SkeletonLoader from "./SkeletonLoader";

const OrderDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetOrderQuery({ id: id ? id : "" });

  const order = data?.data;
  const user = order?.user;

  const [active, setActive] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const { mutateAsync, isLoading: isPending } = useMarkAsProcessingMutation({
    closeModal,
  });
  const { mutateAsync: completeOrder, isLoading: isCompleting } =
    useMarkAsCompleteMutation({
      closeModal,
    });
  const { mutateAsync: cancelOrder, isLoading: isCancelling } =
    useMarkAsCancelMutation({
      closeModal,
    });

  const handlePending = () => {
    mutateAsync({ id: order?.id });
  };

  const handleCompleting = () => {
    completeOrder({ id: order?.id });
  };

  const handleCancellation = () => {
    cancelOrder({ id: order?.id });
  };

  if (isLoading) {
    return (
      <AdminDashboardLayout back>
        <SkeletonLoader />{" "}
      </AdminDashboardLayout>
    );
  }

  return (
    <AdminDashboardLayout back title="Order Details">
      <div className="px-4">
        <div className="bg-[#F1F2FF] rounded-md p-2 flex justify-between items-center my-[15px]">
          <span className="flex gap-2 items-center text-[#3A3FB8]">
            <ArrowLeftRight className="w-6 h-6" />
            <p className="text-[15px]">
              The trading rate for the asset in this order is N1604/$
            </p>
          </span>
          <Link
            to="/admin/rate"
            className="text-[#3A3FB8] text-[15px] underline"
          >
            Update rate
          </Link>
        </div>
        <div className="flex flex-row gap-4 mt-5">
          {/* Left Column */}
          <div className="w-6/12">
            <Scaffold left={<span>Trade Information</span>}>
              <div className="px-3 space-y-2">
                <p>
                  <strong>Type:</strong> Crypto
                </p>
                <p>
                  <strong>Action:</strong> {order?.orderType?.toUpperCase()}
                </p>
                <p>
                  <strong>Asset:</strong> {order?.cryptoType}
                </p>
                <p>
                  <strong>Network:</strong> {order?.network}
                </p>
                <p>
                  <strong>Status:</strong> <StatusTag status={order?.status} />
                </p>
                <p className="flex items-center">
                  <strong>Wallet Address:</strong> {order?.walletAddress}
                  <Copy className="ml-2 cursor-pointer" />
                </p>
              </div>
            </Scaffold>

            <Scaffold left={<span>Amount</span>}>
              <div className="px-3 space-y-2">
                <p>
                  <strong>Amount:</strong> {order?.amount?.toLocaleString()} NGN
                </p>
                <p>
                  <strong>Network Fee:</strong>{" "}
                  {order?.networkFee?.toLocaleString()} NGN
                </p>
                <p>
                  <strong>Max Total (NGN):</strong>{" "}
                  {order?.totalAmount?.toLocaleString()} NGN
                </p>
                <p>
                  <strong>To Send:</strong> {order?.receivedCrypto} ETH
                </p>
              </div>
            </Scaffold>

            <Scaffold left={<span>User Information</span>}>
              <div className="px-3 space-y-2">
                <p>
                  <strong>User ID:</strong> #{user?.id}
                </p>
                <p>
                  <strong>Name:</strong> {user?.firstName} {user?.lastName}
                </p>
                <p>
                  <strong>Email:</strong> {user?.email}
                </p>
              </div>
            </Scaffold>
          </div>

          {/* Right Column */}
          <div className="w-6/12">
            <div className="flex gap-[10px] mb-3">
              <button
                className={`flex-1 p-2 rounded-md text-white ${
                  order?.status === "cancelled" || order?.status === "completed"
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-red-500"
                }`}
                disabled={
                  order?.status === "cancelled" || order?.status === "completed"
                }
                onClick={() => {
                  setIsModalVisible(true);
                  setActive("cancel");
                }}
              >
                Cancel Order
              </button>
              <button
                className={`flex-1 rounded-md text-white ${
                  order?.status === "cancelled" || order?.status === "completed"
                    ? "bg-gray-400 cursor-not-allowed"
                    : order?.status === "processing"
                    ? "bg-yellow-500 opacity-50 cursor-not-allowed"
                    : "bg-yellow-500"
                }`}
                disabled={
                  order?.status === "cancelled" ||
                  order?.status === "completed" ||
                  order?.status === "processing"
                }
                onClick={() => {
                  setIsModalVisible(true);
                  setActive("processing");
                }}
              >
                Mark as Processing
              </button>
              <button
                className={`flex-1 rounded-md text-white ${
                  order?.status === "cancelled" || order?.status === "completed"
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-[#51B590]"
                }`}
                disabled={
                  order?.status === "cancelled" || order?.status === "completed"
                }
                onClick={() => {
                  setIsModalVisible(true);
                  setActive("completed");
                }}
              >
                Mark as Completed
              </button>
            </div>

            <Modal
              open={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              closeIcon={false}
              footer={null}
            >
              <div className="bg-white rounded-md">
                <h1 className="text-xl font-bold">
                  {active === "cancel"
                    ? "Cancel Order"
                    : active === "processing"
                    ? "Mark as Processing"
                    : "Mark as Completed"}
                </h1>
                <p className="text-gray-500">
                  {active === "cancel"
                    ? "Are you sure you want to cancel this order? This action cannot be undone."
                    : active === "processing"
                    ? "Are you sure you want to mark this order as processing?"
                    : "Are you sure you want to mark this order as completed?"}
                </p>
                <div className="flex gap-4 mt-4">
                  <button
                    className="w-6/12 bg-gray-300 text-black rounded-md p-3"
                    onClick={() => setIsModalVisible(false)}
                  >
                    Cancel
                  </button>
                  {active === "cancel" && (
                    <button
                      disabled={isPending}
                      className={`w-6/12 rounded-md text-white p-3 font-bold bg-red-500 ${
                        isCancelling && "opacity-50 cursor-not-allowed"
                      }`}
                      onClick={() => {
                        handleCancellation();
                      }}
                    >
                      Confirm
                    </button>
                  )}
                  {active === "processing" && (
                    <button
                      disabled={isPending}
                      className={`w-6/12 rounded-md text-white p-3 font-bold bg-yellow-500 ${
                        isPending && "opacity-50 cursor-not-allowed"
                      }`}
                      onClick={() => {
                        handlePending();
                      }}
                    >
                      Confirm
                    </button>
                  )}
                  {active === "completed" && (
                    <button
                      disabled={isPending}
                      className={`w-6/12 rounded-md text-white p-3 font-bold bg-green-500 ${
                        isCompleting && "opacity-50 cursor-not-allowed"
                      }`}
                      onClick={() => {
                        handleCompleting();
                      }}
                    >
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </Modal>
            <Scaffold left={<span>Receipt</span>}>
              <div className="px-3 space-y-2">
                <p>
                  <a
                    href={order?.receipt}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    Click to View Receipt
                  </a>
                </p>
              </div>
            </Scaffold>

            <Scaffold left={<span>Make Payment</span>}>
              <div className="px-3 space-y-2">
                <p>
                  <strong>Amount:</strong> {order?.receivedCrypto} ETH
                </p>
                <p>
                  <strong>Wallet Address:</strong> {order?.walletAddress}
                </p>
                <p>
                  <strong>Network:</strong> {order?.network}
                </p>
              </div>
            </Scaffold>

            <Scaffold left={<span>Activity Log</span>}>
              <div className="px-3 space-y-2">
                <p>
                  <strong>Pending:</strong>{" "}
                  {new Date(order?.createdAt).toLocaleString()}
                </p>
                <p>
                  <strong>Processing:</strong> -
                </p>
                <p>
                  <strong>Completed:</strong> -
                </p>
              </div>
            </Scaffold>

            <Scaffold left={<span>Support Ticket</span>}>
              <div className="px-3">
                <p className="text-gray-500">No Ticket Created</p>
              </div>
            </Scaffold>
          </div>
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default OrderDetails;
