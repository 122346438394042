import React from "react";

interface StatusTagProps {
  status: "processing" | "cancelled" | "completed" | "pending";
}

const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  const statusColors: Record<StatusTagProps["status"], string> = {
    processing: "bg-yellow-500",
    cancelled: "bg-red-500",
    completed: "bg-green-400",
    pending: "bg-black",
  };

  return (
    <span className={`px-3 py-1 text-white rounded-md ${statusColors[status]}`}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

export default StatusTag;
