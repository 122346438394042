import { useMutation } from "react-query";
import { message } from "antd";
import { useGetAdminToken } from "../../../utils/useGetAdminToken";
import { queryClient } from "../../../services/queryClient";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";

interface SubjectPayload {
  id: string;
}

export const useMarkAsProcessingMutation = ({ closeModal }: any) => {
  const token = useGetAdminToken();

  return useMutation(
    async ({ id }: SubjectPayload) => {
      const response = await adminAxiosInstance.patch(
        `admins/orders/${id}/mark-processing`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: async () => {
        message.success("Order marked as processing");
        queryClient.invalidateQueries("useGetOrderQuery");
        closeModal();
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.message || "Failed... Please try again.";
        message.error(errorMessage);
      },
    }
  );
};
