import React, { ReactNode, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import withAuth from "../hoc/useAuth";

type Props = { children: ReactNode };

const UserAppLayout = ({ children }: Props) => {
  const logo = require("../assets/images/logo.png");
  const profile = require("../assets/images/Profile.png");
  const payment = require("../assets/images/Payment.png");
  const settings = require("../assets/images/Settings.png");
  const location = useLocation();
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    navigate("/");
    localStorage.removeItem("FLYNTPAY_USER_TOKEN");
  };

  return (
    <div className="h-screen flex flex-col bg-black">
      <nav className="container max-w-[1200px] mx-auto flex justify-between items-center py-[30px] md:px-0 px-4 relative">
        <Link to="/app/dashboard">
          <img src={logo} alt="logo" className="h-[30px]" />
        </Link>
        <span className="hidden md:flex gap-[20px] items-center justify-between">
          <Link
            to="/app/dashboard"
            className={`${
              location?.pathname.includes("/app/dashboard")
                ? "text-white font-bold underline underline-offset-8"
                : "text-ash"
            }`}
          >
            Dashboard
          </Link>
          <Link
            to="/app/buy-crypto"
            className={`${
              location?.pathname.includes("/app/buy-crypto")
                ? "text-white font-bold underline underline-offset-8"
                : "text-ash"
            }`}
          >
            Buy Crypto
          </Link>
          <Link
            to="/app/sell-crypto"
            className={`${
              location?.pathname.includes("/app/sell-crypto")
                ? "text-white font-bold underline underline-offset-8"
                : "text-ash"
            }`}
          >
            Sell Crypto
          </Link>
          <Link
            to="/app/sell-giftcards"
            className={`${
              location?.pathname.includes("/app/sell-giftcards")
                ? "text-white font-bold underline underline-offset-8"
                : "text-ash"
            }`}
          >
            Sell Giftcard
          </Link>
          <Link
            to="/app/more"
            className={`${
              location?.pathname.includes("/app/more")
                ? "text-white font-bold underline underline-offset-8"
                : "text-ash"
            }`}
          >
            More
          </Link>
        </span>

        {/* Profile and Dropdown */}
        <span
          className="md:flex gap-[10px] items-center cursor-pointer hidden"
          onClick={toggleDropdown}
        >
          <img
            src="https://placehold.co/150.png"
            alt="user avatar"
            className="h-[25px] w-[25px] rounded-full"
          />
          <h3 className="text-white font-medium">@10xstark</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-4 h-4 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </span>
        <MenuIcon onClick={toggleMenu} />

        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute right-0 top-full mt-2 w-[250px] rounded-md text-white bg-[#0d0d0d] border-[1px] border-[#242424] p-[12px] z-10"
          >
            <Link to="/app/profile" className="flex items-center gap-3 mb-3">
              <img src={profile} alt="" className="h-[30px]" />
              <div className="flex-1 flex items-center justify-between">
                <h3>Profile</h3>
                <Caret />
              </div>
            </Link>
            <Link to="/app/payment" className="flex items-center gap-3 mb-3">
              <img src={payment} alt="" className="h-[30px]" />
              <div className="flex-1 flex items-center justify-between">
                <h3>Payment</h3>
                <Caret />
              </div>
            </Link>
            <Link to="/app/settings" className="flex items-center gap-3">
              <img src={settings} alt="" className="h-[30px]" />
              <div className="flex-1 flex items-center justify-between">
                <h3>Settings</h3>
                <Caret />
              </div>
            </Link>
            <button
              onClick={handleLogout}
              className="bg-gradient-to-b from-[#838383] to-[#232323] w-full rounded-md p-[10px] mt-[20px]"
            >
              Logout
            </button>
          </div>
        )}
      </nav>

      {/* Slide-in menu for mobile */}
      <div
        className={`fixed top-0 left-0 w-full h-full bg-black z-20 transition-all ease-in-out duration-300 ${
          menuOpen ? "transform translate-x-0" : "transform -translate-x-full"
        } md:hidden`}
      >
        {/* Close Button */}
        <button
          onClick={() => setMenuOpen(false)}
          className="absolute top-4 right-4 text-white text-2xl"
        >
          &times;
        </button>

        <div className="flex flex-col items-center justify-center h-full text-white">
          <Link
            to="/app/dashboard"
            className="py-2"
            onClick={() => setMenuOpen(false)}
          >
            Dashboard
          </Link>
          <Link
            to="/app/buy-crypto"
            className="py-2"
            onClick={() => setMenuOpen(false)}
          >
            Buy Crypto
          </Link>
          <Link
            to="/app/sell-crypto"
            className="py-2"
            onClick={() => setMenuOpen(false)}
          >
            Sell Crypto
          </Link>
          <Link
            to="/app/sell-giftcards"
            className="py-2"
            onClick={() => setMenuOpen(false)}
          >
            Sell Giftcard
          </Link>
          <Link
            to="/app/more"
            className="py-2"
            onClick={() => setMenuOpen(false)}
          >
            More
          </Link>
        </div>
      </div>

      <div className="wrapper flex-1 overflow-y-auto">
        <div className="container max-w-[1200px] mx-auto px-4">{children}</div>
      </div>
    </div>
  );
};

export default withAuth(UserAppLayout);

const Caret = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-4 text-[#777777]"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

const MenuIcon = ({ onClick }: { onClick: () => void }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6 text-white md:hidden block cursor-pointer"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
      />
    </svg>
  );
};
