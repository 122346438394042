import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Pagination,
  Spin,
  Table,
  Tabs,
} from "antd";
import {
  FileTextOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import AdminDashboardLayout from "../../../common/AdminDashboardLayout";
import { useGetOrdersQuery } from "../../../hooks/Admin/Orders/useGetOrdersQuery";
import NairaSign from "../../../components/NairaSign";
import StatusTag from "../../../components/StatusTag";

const AdminOrder = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const pageFromUrl = Number(searchParams.get("page")) || 1;
  const [page, setPage] = useState(pageFromUrl);
  const [search, setSearch] = useState("");
  const [orderType, setOrderType] = useState("");
  // const [selected, setSelected] = useState<"buy" | "sell" | "">("buy");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const { data, isLoading } = useGetOrdersQuery({
    page,
    search,
    orderType,
  });

  useEffect(() => {
    setSearchParams({ page: String(page) });
  }, [page, setSearchParams]);

  const columns = [
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
      render: (orderType: string) => (
        <span
          className={`p-2 rounded-md text-[10px] ${
            orderType === "buy" ? "bg-green-100" : "bg-red-100"
          }`}
        >
          {orderType === "buy" ? "Buy" : "Sell"}
        </span>
      ),
    },
    {
      title: "Crypto Type",
      dataIndex: "cryptoType",
      key: "cryptoType",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => (
        <span>
          <NairaSign /> {`${(amount / 100).toLocaleString()}`}
        </span>
      ),
    },
    {
      title: "Received Crypto",
      dataIndex: "receivedCrypto",
      key: "receivedCrypto",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (total: number) => (
        <span>
          <NairaSign /> {`${(total / 100).toLocaleString()}`}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: any) => <StatusTag status={status} />,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) =>
        new Date(createdAt).toLocaleString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        }),
    },
  ];

  const tabs = [
    { key: "", label: "New Orders", icon: <FileTextOutlined /> },
    {
      key: "processing",
      label: "Processing Orders",
      icon: <ClockCircleOutlined />,
    },
    {
      key: "completed",
      label: "Completed Orders",
      icon: <CheckCircleOutlined />,
    },
    {
      key: "cancelled",
      label: "Cancelled Orders",
      icon: <CloseCircleOutlined />,
    },
  ];

  const handleMenuClick = ({ key }: { key: string }) => {
    setOrderType(key as "buy" | "sell" | "");
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="">All</Menu.Item>
      <Menu.Item key="buy">Buy</Menu.Item>
      <Menu.Item key="sell">Sell</Menu.Item>
    </Menu>
  );

  return (
    <AdminDashboardLayout
      title={`Orders (${data?.data?.meta?.totalItems || 0})`}
      right={
        <Input
          placeholder="Search"
          className="p-2"
          onChange={handleSearchChange}
        />
      }
    >
      <div className="px-4">
        <div className="flex justify-between items-center">
          <Tabs
            activeKey={orderType}
            onChange={setOrderType}
            tabBarExtraContent={isLoading && <Spin />}
            items={tabs.map(({ key, label, icon }) => ({
              key,
              label: (
                <button onClick={() => setOrderType(key)}>
                  {icon} {label}
                </button>
              ),
            }))}
          />
          <div className="flex gap-2 items-center">
            <span>Order Type:</span>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {orderType.charAt(0).toUpperCase() + orderType.slice(1)}{" "}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data?.data?.data}
          loading={isLoading}
          className="manrope cursor-pointer"
          scroll={{ y: "60vh" }}
          pagination={false}
          onRow={(record: any) => ({
            onClick: () => navigate(`/admin/order/${record?.id}`),
          })}
        />
        <Pagination
          current={page}
          total={data?.data?.meta?.totalItems || 0}
          pageSize={data?.data?.meta?.itemsPerPage || 15}
          onChange={(newPage) => setPage(newPage)}
          className="mt-4"
        />
      </div>
    </AdminDashboardLayout>
  );
};

export default AdminOrder;
