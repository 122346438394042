import { message } from "antd";
import { useState, ChangeEvent } from "react";
import { useGetUserToken } from "../../../utils/useGetUserToken";
import axios from "axios";
import { useGetAdminCryptoRates } from "../../../hooks/Admin/Rates/useGetAdminCryptoRates";

const exchange = require("../../../assets/images/Rates.png");
const btc = require("../../../assets/images/btcc.png");
const bnb = require("../../../assets/images/bnb.png");
const eth = require("../../../assets/images/eth.png");
const sol = require("../../../assets/images/sol.png");
const usdt = require("../../../assets/images/usdt.png");
const wave = require("../../../assets/images/usdt.png");

export const useSellCryptohelper = () => {
  const [step, setStep] = useState<number>(1);
  const initialState = {
    orderType: "sell",
    cryptoType: "",
    network: "",
    walletAddress: "",
    amount: "",
    receivedAmount: "",
    acctNumber: "",
    paymentMethod: "",
    networkFee: "",
    totalAmount: "",
  };

  const [payload, setPayload] = useState(initialState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tokens = [
    { id: "btc", name: "BTC", fullName: "Bitcoin", img: btc },
    { id: "bnb", name: "BNB", fullName: "Binance Coin", img: bnb },
    { id: "sol", name: "SOL", fullName: "Solana", img: sol },
    { id: "usdt", name: "USDT", fullName: "Tether USD", img: usdt },
    { id: "eth", name: "ETH", fullName: "Ethereum", img: eth },
  ];

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPayload((prevPayload) => ({ ...prevPayload, [name]: value }));
  };

  const token = useGetUserToken();

  const [file, setFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");

  const assetMapping: Record<string, string> = {
    Bitcoin: "bitcoin",
    "Binance Coin": "binance",
    Solana: "solana",
    "Tether USD": "usd_tether",
    Ethereum: "ethereum",
  };

  const { data: rates } = useGetAdminCryptoRates();

  const getBuyRate = (cryptoType: string) => {
    const token = tokens.find((t) => t.id === cryptoType);
    if (!token) return 0;

    const assetName = assetMapping[token.fullName];
    if (!assetName) return 0;

    const rate = rates?.data?.data.find(
      (r: any) => r.asset.toLowerCase() === assetName
    );

    return rate?.sellRate / 100 || 0;
  };

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev) => ({ ...prev, receiptUrl: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;
    // setFile(selectedFile);
    // if (selectedFile) {
    //   await handleUpload(selectedFile);
    // }
  };

  return {
    step,
    setStep,
    payload,
    setPayload,
    tokens,
    handleChange,
    exchange,
    btc,
    bnb,
    eth,
    sol,
    usdt,
    wave,
    handleFileChange,
    file,
    selectedFile,
    initialState,
    isModalOpen,
    setIsModalOpen,
    getBuyRate,
    handleUpload,
  };
};
