import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";
import { message } from "antd";

export const useLoginMutation = () => {
  const navigate = useNavigate();

  return useMutation(
    (payload: { email: string }) =>
      axiosInstance.post("/auth/login", payload).then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data?.message);
        navigate("/");
      },
      onError: (error: any) => {
        message.error(error?.response?.data?.message);
        console.error("login error", error?.response?.data?.message);
      },
    }
  );
};
