import { Check } from "lucide-react";
import React from "react";

const CryptoSelection = ({
  tokens,
  setPayload,
  payload,
  setStep,
  handleChange,
}: any) => {
  return (
    <div>
      <div className="flex flex-col mt-[5px] gap-[0px] border-b-[1px] border-[#242424]">
        {tokens.map((token: any) => (
          <div
            key={token.id}
            onClick={() =>
              setPayload({
                ...payload,
                cryptoType: token.id,
                network: "",
              })
            }
            className={`flex gap-[8px] items-center px-[20px] py-[11px] cursor-pointer ${
              payload?.cryptoType === token.id ? "bg-[#071E16]" : ""
            }`}
          >
            <img src={token.img} alt={token.fullName} className="h-[35px]" />
            <div className="flex flex-1 flex-row items-center justify-between">
              <div>
                <h3 className="text-white font-medium">{token.name}</h3>
                <p className="text-[#474747]">{token.fullName}</p>
              </div>
              {payload?.cryptoType === token.id && <Check color="#51B590" />}
            </div>
          </div>
        ))}
      </div>

      <div className="px-[20px] my-[20px]">
        <h3 className="text-[#727272]">Network</h3>
        <select
          name="network"
          disabled={payload?.cryptoType === ""}
          value={payload?.network}
          onChange={handleChange}
          className="mt-[10px] w-full p-3 rounded-md bg-[#040404] text-[#727272]"
        >
          <option value="">Select Network</option>
          {payload?.cryptoType === "btc" && (
            <option value="btc">Bitcoin Network</option>
          )}
          {payload?.cryptoType === "usdt" && (
            <>
              <option value="erc-20">Ethereum (ERC-20)</option>
              <option value="trc-20">Tron (TRC-20)</option>
              <option value="bep-20">Binance Smart Chain (BEP-20)</option>
              <option value="spl">Solana (SPL)</option>
              <option value="algo">Algorand (ALGO)</option>
              <option value="matic">Polygon (MATIC)</option>
              <option value="avalanche">Avalanche C-Chain</option>
            </>
          )}
          {payload?.cryptoType === "eth" && (
            <>
              <option value="erc-20">Ethereum (ERC-20)</option>
              <option value="arbitrum">Arbitrum</option>
              <option value="optimism">Optimism</option>
              <option value="polygon">Polygon</option>
            </>
          )}
          {payload?.cryptoType === "bnb" && (
            <>
              <option value="bep-20">Binance Smart Chain (BEP-20)</option>
              <option value="bep-2">Binance Chain (BEP-2)</option>
            </>
          )}
          {payload?.cryptoType === "sol" && (
            <option value="bep-20">Solana (SPL)</option>
          )}
        </select>

        <h3 className="mt-[10px] text-[#727272]">Wallet Address</h3>
        <input
          type="text"
          name="walletAddress"
          value={payload?.walletAddress}
          onChange={handleChange}
          className="mt-[10px] w-full p-2 rounded-md bg-[#040404] text-[#727272] border-[1px] border-[#242424]"
          placeholder="Enter your wallet address"
        />
        <button
          disabled={!payload.network || !payload?.walletAddress}
          onClick={() => setStep(2)}
          className="w-full mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CryptoSelection;
