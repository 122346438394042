// import { useQuery } from "react-query";
// import { useGetUserToken } from "../../utils/useGetUserToken";
// import { axiosInstance } from "../../utils/axiosInstance";

// interface GetOrdersParams {
//   // page: number;
//   // search: string;
//   // orderType: string;
//   // selected?: string;
// }

// export const useGetUserOrders = ({}: // page,
// // search,
// // orderType,
// // selected,
// GetOrdersParams) => {
//   const token = useGetUserToken();

//   return useQuery({
//     queryKey: ["useGetOrdersQuery"],
//     queryFn: async () => {
//       if (!token) throw new Error("Token not found");

//       // const params = { page, search, orderType };
//       // const filteredParams = Object.fromEntries(
//       //   Object.entries(params).filter(([_, v]) => v !== "" && v !== undefined)
//       // );

//       const response = await axiosInstance.get("/admins/orders", {
//         headers: { Authorization: `Bearer ${token}` },
//         // params: { ...filteredParams },
//       });

//       return response.data;
//     },
//     onError: (error) => console.error("Error fetching user data:", error),
//   });
// };

import { useQuery } from "react-query";
import { useGetUserToken } from "../../utils/useGetUserToken";
import { axiosInstance } from "../../utils/axiosInstance";

export const useGetUserOrders = () => {
  const token = useGetUserToken();

  return useQuery({
    queryKey: ["useGetUserOrders"],
    queryFn: async () => {
      if (!token) throw new Error("Token not found");

      const response = await axiosInstance.get("/admins/orders", {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    },
    onError: (error) => console.error("Error fetching user data:", error),
  });
};
