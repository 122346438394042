import { useQuery } from "react-query";
import { useGetAdminToken } from "../../../utils/useGetAdminToken";
import { adminAxiosInstance } from "../../../utils/adminAxiosInstance";

interface GetOrdersParams {
  page: number;
  search: string;
  orderType: string;
  selected?: string;
}

export const useGetOrdersQuery = ({
  page,
  search,
  orderType,
  selected,
}: GetOrdersParams) => {
  const token = useGetAdminToken();

  return useQuery({
    queryKey: ["useGetOrdersQuery", page, search, orderType, selected],
    queryFn: async () => {
      if (!token) throw new Error("Token not found");

      const params = { page, search, orderType };
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, v]) => v !== "" && v !== undefined)
      );

      const response = await adminAxiosInstance.get("/admins/orders", {
        headers: { Authorization: `Bearer ${token}` },
        params: { ...filteredParams, sort: "desc" },
      });

      return response.data;
    },
    onError: (error) => console.error("Error fetching user data:", error),
  });
};
