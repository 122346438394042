import React from "react";
import NairaSign from "../../../../components/NairaSign";

const ReviewOrder = ({
  payload,
  usdt,
  btc,
  wave,
  eth,
  sol,
  getBuyRate,
  setStep,
}: any) => {
  return (
    <div className="p-[20px]">
      <p className="text-[#727272] mb-[10px]">Token Details</p>
      <div className="text-white mb-[1px] bg-[#242424] border-[1px] border-[#242424] rounded-md">
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span>Token</span>
          <span className="flex gap-2 items-center">
            {payload?.network}{" "}
            {payload?.network === "USDT" && (
              <img src={usdt} className="h-[40px] w-[40px]" alt="usdt" />
            )}
            {payload?.network === "Btc" && (
              <img src={btc} className="h-[40px] w-[40px]" alt="btc" />
            )}
            {payload?.network === "Wave" && (
              <img src={wave} className="h-[40px] w-[40px]" alt="wave" />
            )}
            {payload?.network === "Eth" && (
              <img src={eth} className="h-[40px] w-[40px]" alt="eth" />
            )}
            {payload?.network === "Sol" && (
              <img src={sol} className="h-[40px] w-[40px]" alt="sol" />
            )}
          </span>
        </div>
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span className="text-[#c2c2c2]">Network</span>
          <span className="flex gap-2 items-center">{payload?.network}</span>
        </div>
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span className="text-[#c2c2c2]">Wallet Address: </span>
          <span className="flex gap-2 items-center">
            {payload?.walletAddress}
          </span>
        </div>
      </div>
      <h3 className="mt-[10px] mb-[5px] text-[#727272]">Amount</h3>
      <div className="text-white mb-[1px] bg-[#242424] border-[1px] border-[#242424] rounded-md">
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span className="text-[#c2c2c2]">Amount</span>
          <span className="flex gap-2 items-center uppercase">
            {+payload?.amount.toLocaleString()} {payload?.cryptoType}
          </span>
        </div>
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span className="text-[#c2c2c2]">You will Recieve </span>
          <span className="flex gap-2 items-center">
            &#8358;{" "}
            {getBuyRate(payload.cryptoType) && payload.amount
              ? (
                  +payload.amount * getBuyRate(payload.cryptoType)
                ).toLocaleString()
              : 0}
          </span>
        </div>
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span className="text-[#c2c2c2]">Network Fee </span>
          <span className="flex gap-2 items-center">
            <NairaSign /> 500
          </span>
        </div>
        <div className="flex items-center justify-between p-[20px] bg-[#0D0D0D] m-[1px] rounded-md">
          <span className="text-[#c2c2c2]">Max Total </span>
          <span className="flex gap-2 items-center">
            &#8358; {payload?.totalAmount.toLocaleString()}
          </span>
        </div>
      </div>

      <div className="flex gap-[10px]">
        <button
          onClick={() => setStep(2)}
          className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
        >
          Back
        </button>
        <button
          onClick={() => setStep(4)}
          className="w-6/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md"
        >
          Make Payment
        </button>
      </div>
    </div>
  );
};

export default ReviewOrder;
