import { message } from "antd";
import axios from "axios";
import { CloudUpload, Copy, X } from "lucide-react";
import React from "react";

const UploadReceipt = ({
  payload,
  exchange,
  setIsModalOpen,
  fileInputRef,
  setPayload,
  handleSubmit,
  setStep,
  isLoading,
}: any) => {
  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      handleUpload(e.dataTransfer.files[0]);
    }
  };

  const token = localStorage.getItem("FLYNTPAY_USER_TOKEN");

  const handleUpload = async (selectedFile: File) => {
    const formData = new FormData();
    formData.append("files", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/misc/file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("File uploaded successfully!");
      setPayload((prev: any) => ({ ...prev, receipt: response.data.data }));
      console.log(response.data);
    } catch (error) {
      message.error("File upload failed!");
      console.error(error);
    }
  };
  return (
    <div className="px-[20px]">
      <h3 className="text-white">
        You are about to sell {payload?.amount.toLocaleString()} worth of{" "}
        <span className="capitalize">{payload?.cryptoType}</span>. Make payment
        to the account provided below within the time frame above
      </h3>
      <div className="flex p-[15px] mt-[25px] rounded-md gap-[6px] items-center bg-[#5d5fa62a] border-dashed border-[2px] border-[#5D60A6]">
        <h3 className="text-[#5D60A6]">
          Make sure you are sending on the solana network, transaction from any
          other network will result to loss of token
        </h3>
      </div>
      <div className="mt-3 rounded-[12px] bg-black border-[1px] border-[#242424] p-2 flex flex-col items-center justify-center pt-[25px]">
        {/* <img src={exchange} alt="" className="h-[40px] w-[40px] mb-[10px]" /> */}
        {/* <p className="text-white font-bold mb-[25px]">Kuda Microfinance Bank</p> */}
        {/* <p className="text-[#727272]">Account Number</p> */}
        <p className="text-white font-bold mb-[10px]">
          GbXACED1Mnn7sghDemHTWuHs2pv2eXV1t63fEqPAVTTx
        </p>
        <span
          className="flex gap-2 items-center text-[#3A3FB8] cursor-pointer mb-[25px]"
          onClick={() => {
            navigator.clipboard.writeText(
              "GbXACED1Mnn7sghDemHTWuHs2pv2eXV1t63fEqPAVTTx"
            );
            message.success("Wallet address copied to clipboard");
          }}
        >
          <Copy />
          <p className="font-bold">Copy</p>
        </span>
      </div>

      {payload?.receipt === "" ? (
        <div
          className="cursor-pointer border border-dashed border-[#474747] bg-transparent mt-[10px] rounded-[12px] p-[20px] flex flex-col items-center text-center"
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onClick={handleClick}
        >
          <input
            type="file"
            accept="image/jpeg, image/png"
            className="hidden"
            ref={fileInputRef}
            onChange={(e) => {
              if (e.target.files?.length) {
                handleUpload(e.target.files[0]);
              }
            }}
          />
          <div className="flex flex-col items-center gap-2">
            <div className="bg-[#242424] text-[#727272] p-3 rounded-full">
              <CloudUpload size={30} />
            </div>
            <p className="text-white font-medium">
              Drag and drop receipt to upload
            </p>
            <p className="text-gray-400 text-sm">
              Accepted formats:{" "}
              <span className="text-gray-300">.JPEG, .PNG</span> Maximum file
              size: 20MB
            </p>
            <p className="text-[#3A3FB8] text-sm cursor-pointer underline">
              Or browse file
            </p>
          </div>
        </div>
      ) : (
        <div className="relative w-40 h-40 border border-gray-600 rounded-lg overflow-hidden cursor-pointer">
          <img
            src={payload.receipt}
            alt="Uploaded receipt"
            className="w-full h-full object-cover"
            onClick={() => setIsModalOpen(true)}
          />
          <button
            className="absolute top-1 right-1 bg-black/60 text-white p-1 rounded-full"
            onClick={(e) => {
              e.stopPropagation();
              setPayload({ ...payload, receipt: "" });
            }}
          >
            <X size={18} />
          </button>
        </div>
      )}
      <div className="flex gap-[10px]">
        <button
          onClick={() => setStep(3)}
          className="w-3/12 mb-[20px] mt-[20px] bg-gradient-to-b from-[#838383] to-[#232323] text-white font-bold py-2 px-4 rounded-md"
        >
          Back
        </button>
        <button
          disabled={payload?.receipt === ""}
          onClick={handleSubmit}
          className={`w-9/12 mb-[20px] mt-[20px] cursor-pointer bg-gradient-to-b from-[#686DEA] to-[#1C1F60] text-white font-bold py-2 px-4 rounded-md ${
            payload?.receipt === "" ? "opacity-50" : ""
          }`}
        >
          {isLoading ? "Loading..." : "I have transferred crypto"}
        </button>
      </div>
    </div>
  );
};

export default UploadReceipt;
