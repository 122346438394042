import React from "react";

import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.css";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/queryClient";
import Dashboard from "./pages/User/Dashboard/Dashboard";
import BuyCrypto from "./pages/User/BuyCrypto/BuyCrypto";
import SellGiftcards from "./pages/User/SellGiftcards/SellGiftcards";
import More from "./pages/User/More/More";
import Profile from "./pages/User/Profile/Profile";
import KYC from "./pages/User/KYC/KYC";
import SignUp from "./pages/User/Auth/SignUp/SignUp";
import Login from "./pages/User/Auth/Login";
import SellCrypto from "./pages/User/SellCrypto/SellCrypto";
import AdminDashbard from "./pages/Admin/Dashboard";
import AdminOrder from "./pages/Admin/Order";
import AdminRate from "./pages/Admin/Rate";
import AdminReport from "./pages/Admin/Report";
import { ConfigProvider } from "antd";
import AdminLogin from "./pages/Admin/Auth";
import OrderDetails from "./pages/Admin/Order/OrderDetails";
import Admins from "./pages/Admin/Admins";
import Error from "./pages/Error";
import Customer from "./pages/Admin/Customer";
import AdminPayout from "./pages/Admin/Payout";
// import Auth from './pages/Auth/Auth';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/create",
    element: <SignUp />,
  },
  {
    path: "/app/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/app/buy-crypto",
    element: <BuyCrypto />,
  },
  {
    path: "/app/sell-crypto",
    element: <SellCrypto />,
  },
  {
    path: "/app/sell-giftcards",
    element: <SellGiftcards />,
  },
  {
    path: "/app/more",
    element: <More />,
  },
  {
    path: "/app/profile",
    element: <Profile />,
  },
  {
    path: "/app/kyc",
    element: <KYC />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashbard />,
  },
  {
    path: "/admin/order",
    element: <AdminOrder />,
  },
  {
    path: "/admin/order/:id",
    element: <OrderDetails />,
  },
  {
    path: "/admin/rate",
    element: <AdminRate />,
  },
  {
    path: "/admin/report",
    element: <AdminReport />,
  },
  {
    path: "/admin/support",
    element: <AdminReport />,
  },
  {
    path: "/admin/customer",
    element: <Customer />,
  },
  {
    path: "/admin/payout",
    element: <AdminPayout />,
  },
  {
    path: "/admin/admin",
    element: <Admins />,
  },
  {
    path: "/admin/audit",
    element: <AdminReport />,
  },
  {
    path: "/admin/settings",
    element: <AdminReport />,
  },
  { path: "*", element: <Error /> },
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3A3FB8",
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
