import { useMutation } from "react-query";
import { axiosInstance } from "../../utils/axiosInstance";
import { message } from "antd";
import { useGetUserToken } from "../../utils/useGetUserToken";
import { useNavigate } from "react-router-dom";

type OrderPayload = {
  orderType: string;
  cryptoType: string;
  network: string;
  walletAddress: string;
  amount: string;
  receivedCrypto?: string;
  networkFee: string;
  totalAmount: string;
  receipt: string;
};

export const useCreateOrderMutation = ({
  payload,
  setPayload,
  initialState,
}: any) => {
  const token = useGetUserToken();

  const navigate = useNavigate();

  return useMutation(
    () =>
      axiosInstance
        .post(
          "/orders",
          {
            ...payload,
            amount: +payload.amount * 100,
            networkFee: +payload.networkFee * 100,
            totalAmount: +payload.totalAmount * 100,
            receivedCrypto:
              payload.orderType === "buy"
                ? payload.receivedCrypto! * 100
                : undefined,
            receivedAmount:
              payload.orderType === "sell"
                ? payload.orderType! * 100
                : undefined,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        message.success(data?.message || "Order created successfully");
        setPayload(initialState);
        navigate("/app/dashboard");
      },
      onError: (error: any) => {
        message.error(
          error.response?.data?.message ||
            "An error occurred while creating the order"
        );
      },
    }
  );
};
