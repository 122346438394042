import { useGetMeQuery } from "../../../hooks/Auth/useGetMeQuery";
import { Avatar } from "antd";

const UserCard = () => {
  const { data } = useGetMeQuery();
  return (
    <div className="md:mt-[30px] mt-[10px] md:mb-[40px] mb-[20px] flex gap-[10px] items-center">
      <Avatar
        style={{ backgroundColor: "#3A3FB8", verticalAlign: "middle" }}
        size="large"
        gap={0}
        className="h-[45px] w-[45px] rounded-full"
      >
        {data?.data?.firstName[0]}
      </Avatar>

      <div>
        <h3 className="text-white font-medium md:text-[20px] text-[15px]">
          Hello{data?.data?.firstName && ", " + data?.data?.firstName}
        </h3>
        <p className="text-[#C2C2C2] md:text-[14px] text-[12px]">
          What are you trading today?
        </p>
      </div>
    </div>
  );
};

export default UserCard;
